<template>
  <v-container fluid class="container">
    <v-data-table
      v-model="selected"
      :headers="this.headers"
      :items="this.data"
      :sort-by.sync="columnName"
      :sort-desc.sync="isDescending"
      :options.sync="options"
      :loading="loadingValidatePaymentForm"
      item-key="id"
      show-select
      @current-items="doSum"
      @toggle-select-all="selectAllToggle"
      @click:row="checkThis"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: [20, 50, 100],
      }"
      class="elevation-3 row-pointer"
    >
      <!-- @input="select($event)" -->
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          :readonly="item.disabled"
          :disabled="item.disabled"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>

      <template v-slot:item.effectDateBrut="{ item }">
        <v-col>{{ item.effectDate }}</v-col>
      </template>

      <template v-slot:item.effectDateEndBrut="{ item }">
        <v-col>{{ item.effectDateEnd }}</v-col>
      </template>
    </v-data-table>

    <!-- class="mx-auto" -->
    <v-card v-if="sumField('balanceBrut') > 0" width="100%" max-width="100%">
      <v-card-text class="text-right">
        <p class="text-h4 text--primary">Total à payer TTC</p>
        <p class="text-h3 text--primary">{{ formatNumber(sumField("balanceBrut")) }} {{ $currency }}</p>
      </v-card-text>

      <v-card-actions>
        <v-col class="text-right">
          <v-btn
            class="tertiary_light button_color--text"
            x-large
            :loading="loadingGetPaymentForm"
            :disabled="isPayedBtnDisable"
            @click="submitGetPaymentForm"
          >
            <v-icon> payment </v-icon>
            Payer en ligne
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogPayment" width="315px" max-width="315px">
      <v-card>
        <v-card-text class="text-center pt-5">
          <div id="myPaymentForm" class="text-center"></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :color="message.type"
      :multi-line="true"
      timeout="3000"
    >
      {{ message.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="button_color--text"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style>
.container {
  padding: 0 !important;
}
</style>

<script>
/* import embedded-form-glue library */
import KRGlue from "@lyracom/embedded-form-glue";

import axios from "axios";

export default {
  name: "PrimesDataTable",
  data: () => ({
    snackbar: false,
    success: false,
    message: [],
    errors: [],

    /* primes */
    selected: [],
    dialogPayment: false,
    disabledCount: 0,
    columnName: "effectDateBrut",
    isDescending: true,
    /* warn fix*/
    options: {},

    loadingGetPaymentForm: false,
    loadingValidatePaymentForm: false,
    isPayedBtnDisable: false,

    cardNumber: "",
    cardExpiry: "",
    cardCvv: "",

    formToken: "",

    cardNumberRules: [
      (value) =>
        !value ||
        value.replace(/\s/g, "").match(/^(?:4[0-9]{12}(?:[0-9]{3})?)$/) || //Visa
        value.replace(/\s/g, "").match(/^(?:5[0-9][0-9]{14})$/) || //MasterCard
        "Le format du numéro de carte n'est pas correct",
    ],
  }),
  props: {
    data: Array,
    headers: Array,
    contract: Object,
    getContractParent: Function,
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    // spécifique KAYOO
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    checkThis(item, row) {
      if (!item.disabled) {
        row.select(!row.isSelected);
      }
    },
    sumField(key) {
      // sum data in give key (property)
      var total = this.selected.reduce((a, b) => a + (b[key] || 0), 0);
      // special XPF toFixed à 0 et non 2 décimales
      // pas de cts
      return (Math.round(total * 100) / 100).toFixed(0);
    },
    doSum(props) {
      this.selected = [];
      const self = this;
      props.forEach((item) => {
        if (item.defaultSelected) {
          self.selected.push(item);
        }
      });
    },
    selectAllToggle(props) {
      if (
        this.selected.length !=
        this.contract.primes.data.length - this.disabledCount
      ) {
        this.selected = [];
        const self = this;
        props.items.forEach((item) => {
          if (item.disabled) {
            self.selected.push(item);
          }
        });
      } else this.selected = [];
    },
    showDialogPayment() {
      if (this.contract.primes.data) {
        this.dialogPayment = true;
      } else {
        this.success = false;
        this.message.type = "error";
        this.message.message =
          "Aucune paiement. Votre demande ne peut pas aboutir";

        this.snackbar = true;
      }
    },
    submitGetPaymentForm() {
      this.loadingGetPaymentForm = true;

      this.getPaymentForm().then((data) => {
        this.formToken = data.formToken;
        
        /* Use your endpoint and personal public key */
        const endpoint = this.$systempay_baseurl;
        const publicKey = this.$systempay_pubkey;
        const formToken = this.formToken;

        KRGlue.loadLibrary(endpoint, publicKey) /* Load the remote library */
          .then(({ KR }) =>
            KR.setFormConfig({
              /* set the minimal configuration */ formToken: formToken,
              "kr-language": "fr-FR" /* to update initialization parameter */,
            })
          )
          .then(({ KR }) => KR.onSubmit(this.submitValidatePayment)) // Custom payment callback
          .then(({ KR }) =>
            KR.addForm("#myPaymentForm")
          ) /* create a payment form */
          .then(({ KR, result }) =>
            KR.showForm(result.formId)
          ); /* show the payment form */

        this.dialogPayment = true;

        this.loadingGetPaymentForm = false;
      });
    },
    getPaymentForm() {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/payment/form/get", {
            amount: this.sumField("balanceBrut").replace(".", "").toString(),
          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)
            let formToken = response.data.formToken;
            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;

            resolve({
              formToken,
              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
    /* Validate the payment data */
    submitValidatePayment(paymentData) {
      
      const uuid = paymentData.clientAnswer.transactions[0].uuid;
      const answer = paymentData.clientAnswer;
      const hash = paymentData.hash;
      const answerHash = JSON.stringify(answer);

      this.dialogPayment = false;
      this.isPayedBtnDisable = true;
      this.validatePayment(uuid, hash, answerHash).then((data) => {
        if (data.status === "PAID") {
          this.success = data.success;
          this.message.type = data.message.type;
          this.message.message = data.message.message;
          if(document.querySelector('#krtoolbar')){
            // Cache la barre de test après le paiement
            document.querySelector('#krtoolbar').style.display = 'none';
          }
        } else {
          this.success = false;
          this.message.type = "error";
          this.message.message = "Paiement en erreur !";
          this.isPayedBtnDisable = false;
        }
        
        this.snackbar = true;
        
      });

      return false; // Return false to prevent the redirection
    },
    validatePayment(uuid, hash, answerHash) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/payment/form/validate", {
            uuid: uuid,
            hash: hash,
            answerHash: answerHash,

            contractId: "" + this.contract.details.id + "",
            amount: this.sumField("balanceBrut").toString(),
            primes: this.selected,
            contractNumber: "" + this.contract.details.number + "",
            isEcheancier: this.contract.computed.isEcheancier,
          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)
            let uuid = response.data.uuid;
            let status = response.data.status;
            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;

            this.getContractParent();

            resolve({
              uuid,
              status,
              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
  },
  watch: {},
};
</script>

<template>
  <div>
    <!-- CHANGER LA CONDITION SUR LES EDITICS -->

    <ValidationObserver ref="autoOptsDisclaimer" v-slot="{ valid }">
      <v-form @submit.prevent="estimateHandler" style="width: 100%">
        <v-row>
          <v-col cols="5" sm="5">
            <v-menu
              ref="menuEffectDate"
              v-model="menuEffectDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|effectDateRule"
                  name="date d'effet"
                >
                  <v-text-field
                    v-model="formatedEffectDate"
                    label="Date d'effet"
                    outlined
                    :error-messages="errors"
                    prepend-icon="calendar"
                    clearable
                    clear-icon="clear"
                    @click:clear="effectDate = ''"
                    v-bind="attrs"
                    @blur="effectDate = parseDate(formatedEffectDate)"
                    v-on="on"
                    required
                    readonly
                  ></v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker
                v-model="effectDate"
                scrollable
                header-color="tertiary"
                color="tertiary_light"
                first-day-of-week="1"
                no-title
                @input="menuEffectDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- <v-col >
                <v-icon>
                  add
                </v-icon>
              </v-col> -->
          <v-col cols="7" sm="7" style="color: grey">
            La date d'effet doit être comprise dans les 35 prochains jours à
            partir de ce jour.
          </v-col>
          <v-col cols="6" v-if="estimate.product.code == $codeAuto">
            <div v-if="estimate.product.code == $codeAuto">
              <v-col class="mt-7" cols="7">
                <v-slider
                  label="Frais de courtage"
                  v-model="options.brokerFees"
                  thumb-label="always"
                  thumb-color="button"
                  step="10"
                  ticks
                  max="150"
                  :disabled="true"
                ></v-slider>
              </v-col>
              <v-col class="mt-7" cols="7">
                <v-slider
                  label="Coeff. de réduction"
                  v-model="options.coeffReduc"
                  thumb-label="always"
                  thumb-color="button"
                  step="1"
                  ticks
                  :disabled="true"
                  max="15"
                ></v-slider>
              </v-col>
            </div>
          </v-col>
          <!-- <v-spacer /> -->
        </v-row>

        <v-row >
          <v-col cols="12" v-if="estimate.product.code == $codeAuto">
            <v-card v-if="estimate.product.code == $codeAuto">
              <v-card-title> Vos options : </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="niveau franchise DTA/VI"
                    >
                      <v-select
                        :items="itemsLists.franchisesDTA"
                        return-object
                        v-model="options.franchiseDTA"
                        item-text="libelle"
                        item-value="code"
                        label="Niveau franchise DTA/VI"
                        outlined
                        :loading="listsLoading"
                        :error-messages="errors"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="niveau franchise bris de glace"
                    >
                      <v-select
                        :items="itemsLists.franchisesBrisGlace"
                        v-model="options.franchiseBrisGlace"
                        return-object
                        item-text="libelle"
                        item-value="code"
                        label="Niveau franchise bris de glace"
                        outlined
                        :loading="listsLoading"
                        :error-messages="errors"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="montant EOA"
                    >
                      <v-select
                        :items="itemsLists.montantsEOA"
                        v-model="options.montantEOA"
                        return-object
                        item-text="libelle"
                        item-value="code"
                        label="Montant EOA"
                        outlined
                        :loading="listsLoading"
                        :error-messages="errors"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="niveau assistance"
                    >
                      <v-select
                        :items="itemsLists.niveauxAssistance"
                        v-model="options.niveauAssistance"
                        return-object
                        item-text="libelle"
                        item-value="code"
                        label="Niveau assistance"
                        outlined
                        :loading="listsLoading"
                        :error-messages="errors"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="garantie conducteur"
                    >
                      <v-select
                        :items="itemsLists.garantiesConducteur"
                        v-model="options.garantieConducteur"
                        return-object
                        item-text="libelle"
                        item-value="code"
                        label="Garantie conducteur"
                        outlined
                        :loading="listsLoading"
                        :error-messages="errors"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="conduite exclusive"
                    >
                      <v-select
                        :items="itemsLists.isConduiteExclusive"
                        v-model="options.isConduiteExclusive"
                        label="Conduite exclusive"
                        outlined
                        :loading="listsLoading"
                        :error-messages="errors"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                 <v-row>
                    <v-col cols="4">
                   
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                        name="valeur conventionnelle majorée"
                      >
                        <v-select
                          :items="itemsLists.isConduiteExclusive"
                          v-model="options.isValeurConvMaj"
                          label="Valeur conventionnelle majorée"
                          outlined
                          :loading="listsLoading"
                          :error-messages="errors"
                          :disabled="true"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="4" offset="8">
            <v-btn
              block
              large
              color="button"
              class="button_color--text"
              type="submit"
              :disabled="loading"
            >
              <v-icon left>search</v-icon> Calculer
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Selectionner votre formule et votre fractionnement
          </v-card-title>
          <v-card-text>
            <v-skeleton-loader
              v-show="loading && Object.keys(estimation).length == 0"
              :loading="true"
              type="table-thead, table-tbody"
            >
            </v-skeleton-loader>
            <v-data-table
              v-show="Object.keys(estimation).length > 0"
              :headers="estimation['headers']"
              :items="estimation['data']"
              hide-default-footer
              :loading="loading"
            >
              <template v-slot:body="{ items }">
                <tr v-for="row in items" :key="row.splitting">
                  <td v-for="(cellData, index) in row" :key="index">
                    <v-radio-group
                      v-if="index != 'splitting'"
                      v-model="formulaSelection"
                    >
                      <v-radio
                        :value="row.splitting + '_' + index"
                        :disabled="true"
                      >
                        <template v-slot:label>
                          <div>
                            <h4>{{ cellData }}</h4>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                    <h3 v-else>{{ cellData }}</h3>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text>
            <p>
              Pour les fractionnements semestriels, trimestriels et mensuels,
              les frais seront appliqués sur le comptant. Les montants affichés
              dans le tableaux ci-dessus pour ces fractionnements sont les
              échéances secondaires.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Vos garanties et options incluses:
            {{ this.formulaSelection.replace("_", " - ") }}
          </v-card-title>
          <v-row>
            <v-col>
             <!-- GLA_ENF-6 -->
                <v-simple-table
                  v-if="
                    Object.keys(estimation).length > 0 &&
                    estimation['formulas'][formulaSelection] != undefined
                  "
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Garantie</th>
                        <th class="text-right">TTC</th>
                        <th class="text-right">Franchise</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(guarantee, i) in estimation['formulas'][
                        formulaSelection
                      ].guarantees.filter(function(g) {
                          return g.isSuscribed
                        })"
                        :key="i"
                      >
                        <td>{{ guarantee.libelle }}</td>
                        <td class="text-right">{{ guarantee.amount }} {{ $currency }}</td>
                        <td class="text-right" v-if="guarantee.franchiseValue > 0">
                          {{ guarantee.franchiseValue }}
                          {{ guarantee.franchiseCurrencylabel }}
                        </td>
                        <td v-else></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.v-slider__thumb-label,
.v- -label-container {
  position: fixed;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<script>
import { mapState, mapActions } from "vuex";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";

import Vue from 'vue';

setInteractionMode("eager");
const customEffectDateRule = function (value) {
  if (value != undefined) {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    var inRangedAllowedDate = Object.assign(new Date(), today);

    /* console.log(inRangedAllowedDate) */
    var parts = value.split("/");
    var occurrenceDate = new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10)
    );

    inRangedAllowedDate.setDate(inRangedAllowedDate.getDate() + 35);

    return today.getTime() <= occurrenceDate.getTime()
      ? inRangedAllowedDate.getTime() >= occurrenceDate.getTime()
        ? true
        : ""
      : "";
  }
};
extend("required", {
  ...required,
  message: "Le champ {_field_} est requis",
});
extend("effectDateRule", {
  validate(value) {
    return customEffectDateRule(value);
  },
});


export default {
  name: "PricingValidation",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    menuEffectDate: false,
    formatedEffectDate: "",

    /*   valid: false, */
    guaranteesSelection: [],
  }),
  props: {},
  created() {
    logger.debug('Création de la vue PricingValidation')
    if (this.estimate.product.code == Vue.prototype.$codeAuto) this.getAutoOptsItemsLists();
    this.formatedEffectDate = this.formatDate(this.effectDate);
    logger.info('Vérification de la date d\'effet..')
    if (!customEffectDateRule(this.formatedEffectDate)) {
      logger.warn('Date d\'effet invalide, remplacée par la date du jour')
      this.effectDate = new Date();
    }
  },
  mounted() {},
  computed: {
    ...mapState({
      loading: (state) => state.transformation.loader["getEstimation"] ?? false,
      /* createLoading: (state) => state.transformation.loader["createEstimate"] ?? false, */
      /* listsLoading: (state) => state.estimation.loader["listAutoOpts"], */
      options: (state) => state.transformation.estimate.productRisk.options,
      estimate: (state) => state.transformation.estimate,
      /* itemsLists: (state) => state.estimation.itemsLists, */
      estimation: (state) => state.transformation.estimation,
      itemsLists: (state) => state.transformation.itemsLists,
      listsLoading: (state) => state.transformation.loader["listAutoOpts"],
      formulaSelection: (state) =>
        state.transformation.estimate.formula.splitting +
        "_" +
        state.transformation.estimate.formula.libelle,
    }),
    effectDate: {
      get: function () {
        return this.estimate.effectDate;
      },
      set: function (v) {
        if (v != undefined) {
          let now = new Date().toISOString();
          console.log(now.toString());
          if (this.isToday(new Date(v)))
            this.$store.commit("transformation/updEffectDate", now);
          else this.$store.commit("transformation/updEffectDate", v);
        }
      },
    },
  },
  methods: {
    ...mapActions("transformation", [
      "getEstimation",
      /* "createEstimate", */
      "getAutoOptsItemsLists",
    ]) /*
    /*  goToCreatedEstimation() {
      this.$router.push("/contract/search/" + this.contractId);
    }, */,
    isToday(someDate) {
      const today = new Date();
      return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      );
    },
    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString('fr-FR', {timeZone: 'Pacific/Noumea'});
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    estimateHandler() {
      this.$refs["autoOptsDisclaimer"].validate().then((valid) => {
        if (valid) {
          /* this.firstLoad = true; */
          this.getEstimation();
        }
      });
    },
  },
  watch: {
    effectDate(val) {
      this.formatedEffectDate = this.formatDate(val);
    },
    formatedEffectDate(val) {
      logger.debug('Remise à zéro de la tarification - Modification de la date d\'effet')
      this.$store.commit("transformation/resetEstimation");
    },
    estimation: {
      handler: function (val, oldVal) {
        if (Object.keys(val).length > 0)
          logger.debug('Selection de la formule choisie')
          this.$store.commit(
            "transformation/updFormula",
            this.formulaSelection
          );
      },
      deep: true,
    },
    // Reinit. la tarification quand le risk est modifié
    /*  /* "estimate.productRisk": {
      handler(val, oldVal) {
        this.$store.commit("transformation/resetEstimation");
      },
      deep: true,
    }, */
  },
};
</script>

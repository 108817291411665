import Vue from 'vue'
import Vuex from 'vuex'


import axios from "axios";

Vue.use(Vuex);

const estimationStoreModule = {
    namespaced: true,
    // https://vuex.vuejs.org/guide/modules.html#module-reuse !!
    state: () => ({
        // Devis
        estimate: {},
        // La tarification
        estimation: {},
        estimationStep: 1,
        riskStep: 1,
        hasBroker: true,
        itemsLists: {
            cities: [],
            claimNatures: [],
            isConduiteExclusive: ["OUI", "NON"],
        },
        snackbars: {
            pricing: {
                state: false,
                content: {
                    type: '',
                    message: '',
                },
            },
        },
        loader: {
            'listClient': false,
            'listVehicle': false,
            'listCities': false,
            'listDriver': false,
            'listAutoOpts': false,
            'getClient': false,
            'getEstimateModel': true,
            'getEstimation': false,
            'createEstimate': false,
        },
    }),
    mutations: {
        updBirthDate(state, birthDate) {
            state.estimate.person.birthDate = birthDate
        },
        updFirstUseDate(state, firstUseDate) {
            state.estimate.productRisk.vehicleInfos.firstUseDate = firstUseDate
        },
        updAcquisitionDate(state, acquisitionDate) {
            state.estimate.productRisk.vehicleInfos.acquisitionDate = acquisitionDate
        },
        updEffectDate(state, effectDate) {
            state.estimate.effectDate = effectDate
        },
        nextEstimationStep(state) {
            state.estimationStep++
            logger.debug('Etape tarification n°' + state.estimationStep)
        },
        nextRiskStep(state) {
            state.riskStep++
            logger.debug('Etape risque n°' + state.riskStep)
        },
        updEstimationStep(state, step) {
            state.estimationStep = step
            logger.debug('Etape tarification n°' + state.estimationStep)
        },
        updRiskStep(state, step) {
            state.riskStep = step
            logger.debug('Etape risque n°' + state.riskStep)
        },
        setPrincipalAssured(state, principalAssured) {
            state.estimate.productRisk.principalAssured = principalAssured
        },
        setSecondariesAssured(state, secondariesAssured) {
            state.estimate.productRisk.secondariesAssured = secondariesAssured
        },
        setRisk(state, risk) {
            state.estimate.productRisk = risk;
        },
        updFormula(state, index) {
            state.estimate.formula = state.estimation.formulas[index]
        },
        updVehicle(state, vehicle) {
            state.estimate.productRisk.vehicle = vehicle
        },
        addDriver(state, payload) {
            state.estimate.productRisk.drivers.push(payload.driver);
        },
        updDriver(state, payload) {
            Object.assign(state.estimate.productRisk.drivers[payload.editedDriverId], payload.driver);
        },
        rmvDriver(state, editedDriverId) {
            state.estimate.productRisk.drivers.splice(editedDriverId, 1)
        },
        updDriverDetails(state, payload) {
            state.estimate.productRisk.drivers[payload.editedDriverId][payload.key] = payload.value
        },
        resetEstimation(state) {
            state.estimation = {}
        },
    },
    getters: {
        /* isVehicleSelected: state => state.estimate.productRisk.vehicle.length > 0, */
    },
    actions: {
        async getEstimateModel({
                                   state,
                                   rootState,
                                   dispatch
                               }, productId) {
            try {
                logger.info('Récupération du model de devis..')
                logger.time("getEstimateModel()");
                const response = await axios.post('/api/estimate/getModel', {
                    productId: productId,
                })

                state.estimate = response.data;
                logger.info('Model Devis -> produit: ' + state.estimate.product.libelle)
                logger.debug('Récupération du code agence..')
                await dispatch('getAgency', null, {
                    root: true
                }).then((hasBroker) => {

                    if (hasBroker) {
                        logger.info('Ajout du code agence de l\'utilisateur au model de devis')
                        state.estimate.agencyCode = rootState.auth.data.user.agency.code;

                        state.loader["getEstimateModel"] = false;
                        state.hasBroker = hasBroker
                    } else {
                        logger.error('Pas de code agence pour le courtier corespondant')
                        state.loader["getEstimateModel"] = false;
                        state.hasBroker = hasBroker
                    }
                }).catch(() => {
                    logger.error('Récupération du code agence : ' + error.message)
                    state.loader["getEstimate"] = false;
                    state.hasBroker = hasBroker
                });

            } catch (error) {
                logger.error('Récupération du model de devis : ' + error.message)
                state.loader['getEstimateModel'] = false;
            }
            logger.timeEnd("getEstimateModel()");
        },
        async fetchClient(context, idClient) {
            logger.info('Récupération du client..')
            logger.time("fetchClient()");
            try {
                context.state.loader['getClient'] = true;
                const response = await axios.post("/api/client/get", {
                    idClient: idClient,
                })

                context.state.estimate.person = response.data.person;
                logger.info('client -> id: ' + response.data.person.id + ', nom: ' + response.data.person.lastname + ' ' + response.data.person.firstname)
                context.state.loader['getClient'] = false;
            } catch (error) {
                logger.error('Récupération du client: ' + error.message)
                context.state.loader['getClient'] = false;
            }
            logger.timeEnd("fetchClient()");
        },
        async getEstimation(context, idClient, rootContext) {
            logger.info('Tarification lancée..')
            logger.time("getEstimation()");
            try {
                context.state.loader['getEstimation'] = true;
                const response = await axios
                    .post("/api/estimate", {
                        estimate: context.state.estimate,
                    });

                context.state.estimation = response.data.estimation;

                context.state.loader['getEstimation'] = false;

                context.state.snackbars['pricing'] = {
                    state: true,
                    content: {
                        type: response.data.message.type,
                        message: response.data.message.message
                    },
                }

            } catch (error) {
                logger.error('Erreur de tarification : ' + error.message)
                context.state.loader['getEstimation'] = false;
                context.state.snackbars['pricing'] = {
                    state: true,
                    content: {
                        type: response.data.message.type,
                        message: response.data.message.message
                    },
                }
            }
            logger.timeEnd("getEstimation()");
        },
        async createEstimate(context, idClient) {
            logger.info('Création du devis..')
            logger.time("createEstimate()");
            try {
                context.state.loader['createEstimate'] = true;

                const response = await axios
                    .post("/api/estimate/creation", {
                        estimate: context.state.estimate,
                    });

                context.state.estimate.id = response.data.contractId;
                context.state.estimate.number = response.data.contractNumber;
                logger.info('Devis -> id : ' + context.state.estimate.id + ', numéro: ' + context.state.estimate.number)

                context.state.loader['createEstimate'] = false;

                context.state.snackbars['pricing'] = {
                    state: true,
                    content: {
                        type: response.data.message.type,
                        message: response.data.message.message
                    },
                }
            } catch (error) {
                logger.error('Création du devis: ' + error.message)
                context.state.loader['createEstimate'] = false;
                context.state.snackbars['pricing'] = {
                    state: true,
                    content: {
                        type: response.data.message.type,
                        message: response.data.message.message
                    },
                }
            }
            logger.timeEnd("createEstimate()");
        },
        async getClientItemsLists(context) {
            const civilitiesReq = axios.get("/api/listItems/civilities");
            const countriesReq = axios.get("/api/listItems/countries");
            const cspsReq = axios.get("/api/listItems/csps");
            const maritalStatusesReq = axios.get("/api/listItems/marStat");
            const relationsReq = axios.get("/api/listItems/relations");

            try {
                context.state.loader['listClient'] = true;
                const responses = await axios.all([
                    civilitiesReq,
                    countriesReq,
                    cspsReq,
                    maritalStatusesReq,
                    relationsReq
                ]);

                context.state.itemsLists['civilities'] = responses[0].data.listItems;
                context.state.itemsLists['countries'] = responses[1].data.listItems;
                context.state.itemsLists['csps'] = responses[2].data.listItems;
                context.state.itemsLists['maritalStatuses'] = responses[3].data.listItems;
                context.state.itemsLists['relations'] = responses[4].data.listItems;

                context.state.loader['listClient'] = false;
                logger.warn('END - ListsItems Client')
            } catch (error) {
                console.log(error)
                this.handleAxiosError(error);
                context.state.loader['listClient'] = false;
            }
        },
        async getVehicleItemsLists(context) {

            const acquisitionModesReq = axios.get(
                "/api/listItems/acquisitionModes"
            );
            const usesReq = axios.get("/api/listItems/uses");
            const tWeightsReq = axios.get("/api/listItems/trailerWeights");
            const tTypesReq = axios.get("/api/listItems/trailerTypes");
            const vehicleOwnerReq = axios.get("/api/listItems/vehicleOwnerTypes");
            const withoutInsuranceRangesReq = axios.get(
                "/api/listItems/withoutInsuranceRanges"
            );

            try {
                context.state.loader['listVehicle'] = true;
                const responses = await axios.all([
                    acquisitionModesReq,
                    usesReq,
                    tWeightsReq,
                    tTypesReq,
                    vehicleOwnerReq,
                    withoutInsuranceRangesReq,
                ]);

                context.state.itemsLists['acquisitionModes'] = responses[0].data.listItems;
                context.state.itemsLists['uses'] = responses[1].data.listItems;
                context.state.itemsLists['trailerWeights'] = responses[2].data.listItems;
                context.state.itemsLists['trailerTypes'] = responses[3].data.listItems;
                context.state.itemsLists['vehicleOwner'] = responses[4].data.listItems;
                context.state.itemsLists['withoutInsuranceRanges'] = responses[5].data.listItems;

                context.state.loader['listVehicle'] = false;
            } catch (error) {
                this.handleAxiosError(error);
                context.state.loader['listVehicle'] = false;
            }


        },
        async getDriverItemsLists(context) {

            const autoCspsReq = axios.get("/api/listItems/autoCsps");
            const insurancedPeriodsReq = axios.get("/api/listItems/insurancedPeriods");
            const drivingTypesReq = axios.get("/api/listItems/drivingTypes");
            const suspendedLicenceReasonsReq = axios.get("/api/listItems/suspendedLicenceReasons");
            const canceledByInsurerReasonsReq = axios.get("/api/listItems/canceledByInsurerReasons");
            const claimResponsabilitiesReq = axios.get("/api/listItems/claimResponsabilities");
            const claimAutoNaturesReq = axios.get("/api/listItems/claimAutoNatures");

            try {
                context.state.loader['listDriver'] = true;
                const responses = await axios
                    .all([
                        autoCspsReq,
                        insurancedPeriodsReq,
                        drivingTypesReq,
                        suspendedLicenceReasonsReq,
                        canceledByInsurerReasonsReq,
                        claimResponsabilitiesReq,
                        claimAutoNaturesReq
                    ]);

                context.state.itemsLists['autoCsps'] = responses[0].data.listItems,
                    context.state.itemsLists['insurancedPeriods'] = responses[1].data.listItems,
                    context.state.itemsLists['drivingTypes'] = responses[2].data.listItems,
                    context.state.itemsLists['suspendedLicenceReasons'] = responses[3].data.listItems,
                    context.state.itemsLists['canceledByInsurerReasons'] = responses[4].data.listItems,
                    context.state.itemsLists['claimResponsabilities'] = responses[5].data.listItems,
                    context.state.itemsLists['claimAutoNatures'] = responses[6].data.listItems,
                    context.state.loader['listDriver'] = false;
            } catch (error) {

                this.handleAxiosError(error);
                context.state.loader['listDriver'] = false;
            }

        },
        async getAutoOptsItemsLists(context) {

            const franchisesDTAReq = axios.get("/api/listItems/franchisesDTA");
            const franchisesBrisGlaceReq = axios.get(
                "/api/listItems/franchisesBrisGlace"
            );
            const montantsEOAReq = axios.get("/api/listItems/montantsEOA");
            const niveauxAssistanceReq = axios.get(
                "/api/listItems/niveauxAssistance"
            );
            const garantiesConducteurReq = axios.get(
                "/api/listItems/garantiesConducteur"
            );

            try {
                context.state.loader['listAutoOpts'] = true;
                const responses = await axios
                    .all([
                        franchisesDTAReq,
                        franchisesBrisGlaceReq,
                        montantsEOAReq,
                        niveauxAssistanceReq,
                        garantiesConducteurReq,
                    ]);

                context.state.itemsLists['franchisesDTA'] = responses[0].data.listItems,
                    context.state.itemsLists['franchisesBrisGlace'] = responses[1].data.listItems,
                    context.state.itemsLists['montantsEOA'] = responses[2].data.listItems,
                    context.state.itemsLists['niveauxAssistance'] = responses[3].data.listItems,
                    context.state.itemsLists['garantiesConducteur'] = responses[4].data.listItems,

                    context.state.loader['listAutoOpts'] = false;
            } catch (error) {

                this.handleAxiosError(error);
                context.state.loader['listAutoOpts'] = false;
            }

        },
        async getCitiesList(context) {
            try {
                context.state.loader['listCities'] = true;
                const response = await axios
                    .get("/api/listItems/citiesByZipCode", {
                        params: {
                            zipCode: context.state.estimate.productRisk.vehicleInfos.zipCode,
                            productCode: context.state.estimate.product.code,
                        },
                    });

                context.state.itemsLists['cities'] = response.data.listItems;

                context.state.estimate.productRisk.vehicleInfos.city = [];

                context.state.loader['listCities'] = false;
            } catch (error) {
                context.state.estimate.productRisk.vehicleInfos.city = [];
                context.state.itemsLists.cities = [];

                context.state.loader['listCities'] = false;
                this.handleAxiosError(error);
            }

            /* } */
            /* console.log(this.risk.vehicle.vehicleInfos.zipCode) */
        },
    }
}

export default estimationStoreModule

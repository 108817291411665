<template>
  <div>
   <ValidationObserver ref="form" v-slot="{ valid }">

    <v-form >
      <div>
        <v-col>
          <ValidationProvider rules="" vid="isPrincipalAssured" name="assuré principal">
          <v-checkbox
            v-model="principalAssured.isPrincipalAssured"
            title="Assuré principal"
            style="color: blue"
            label="Le souscripteur est-il l'assuré principal ?"
          /> 
          </ValidationProvider>           
        </v-col>
      </div>
      <div v-show="!(principalAssured.isPrincipalAssured)">
        <v-row>
        <v-col cols="2">
          <ValidationProvider
                    v-slot="{ errors }"
                    rules="required_if:isPrincipalAssured,false"
                    name="civilité"
                   
                  >
            <v-select
              :items="itemsLists['civilities']"
              item-text="libelleCourt"
              item-value="code"
              v-model="principalAssured.principalAssured.civility"
              return-object
              label="Civilité"
              outlined
              
              required
              :error-messages="errors"
            />
          </ValidationProvider>
          </v-col>
          <v-col cols="5">
             <ValidationProvider
                    v-slot="{ errors }"
                    rules="required_if:isPrincipalAssured,false"
                    name="nom"
                   
                  >
            <v-text-field
              v-model="principalAssured.principalAssured.lastname"
              label="Nom"
              outlined
             
              clearable
              clear-icon="clear"
              required
              
              :error-messages="errors"
            />
             </ValidationProvider>
          </v-col>
          <v-col cols="5">
             <ValidationProvider
                    v-slot="{ errors }"
                    rules="required_if:isPrincipalAssured,false"
                    name="prénom"
                   
                  >
            <v-text-field
              v-model="principalAssured.principalAssured.firstname"
              label="Prénom"
              outlined
             
              clearable
              clear-icon="clear"
              required
              :error-messages="errors"
              
            />
             </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
             <ValidationProvider
                    v-slot="{ errors }"
                    rules="required_if:isPrincipalAssured,false"
                    name="situation matrimoniale"
                   
                  >
            <v-select
              :items="itemsLists['maritalStatuses']"
              v-model="principalAssured.principalAssured.maritalStatus"
              return-object
              item-text="libelle"
              item-value="code"
              label="Sit. Mat."
              outlined
              
              required
              :error-messages="errors"
           />
             </ValidationProvider>
          </v-col>
          <v-col cols="4" sm="4">

            <v-menu
              ref="menuBirthDate"
              v-model="menuBirthDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                 <ValidationProvider
                    v-slot="{ errors }"
                    rules="required_if:isPrincipalAssured,false"
                    name="date de naissance"
                   
                  >
                <v-text-field
                  v-model="formatedBirthDate"
                  label="Date de naissance "
                  outlined
                  persistent-hint
                  prepend-icon="calendar"
                  clearable
                  clear-icon="clear"
                  @click:clear="principalAssured.principalAssured.birthDate = ''"
                  v-bind="attrs"
                  
                  @blur="$set(principalAssured.principalAssured,'birthDate', parseDate(formatedBirthDate))"
                  v-on="on"
                  required
                  :error-messages="errors"
                  placeholder="jj/mm/aaaa"
                  onkeyup="
                  var v = this.value;
                  if (v.match(/^\d{2}$/) !== null) {
                    this.value = v + '/';
                  } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
                    this.value = v + '/';
                  }
                  "
                  maxlength="10"
               />
                 </ValidationProvider>
              </template>
              <v-date-picker
                v-model="principalAssured.principalAssured.birthDate"
                scrollable
                header-color="tertiary"
                color="tertiary_light"
                first-day-of-week="1"
                no-title
                @input="menuBirthDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
            <!-- <v-col cols="4" sm="4">
            <v-text-field
              v-model="principalAssured.principalAssured.profession"
              label="Profession"
              outlined  
              clearable
              clear-icon="clear"
              
            >
            </v-text-field>
          </v-col>-->
        </v-row>
        <!--<v-row>
          <v-col  cols="12" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required_if:isPrincipalAssured,false"
              name="catégorie socioprofessionnelle"
              
            >
              <v-select
                :items="itemsLists['csps']"
                v-model="principalAssured.principalAssured.socioProfessionalCategory"
                return-object
                item-text="libelle"
                item-value="code"
                label="Cat. Socioprofessionnelle"
                outlined
                required
                
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
       
        </v-row>-->
        
      </div>
     <!--  <CRUDDatatable/> -->
     <!-- <VJsf
        v-model="model"
        :schema="schema"
        :options="options"
        @input="logEvent('input', $event)"
        @change="logEvent('change', $event)"
        @input-child="logEvent('input-child', $event)"
        @change-child="logEvent('change-child', $event)"
      >
        <template slot="custom-inputTable" slot-scope="context">
          <VjsfAddDatatable v-bind="context" />
        </template>
      </VJsf>  -->
    </v-form>

    
      </ValidationObserver>
      <VJsf
        v-model="model"
        :schema="schema"
        :options="options"
        @input="logEvent('input', $event)"
        @change="logEvent('change', $event)"
        @input-child="logEvent('input-child', $event)"
        @change-child="logEvent('change-child', $event)"
      >
        <template slot="custom-inputTable" slot-scope="context">
          <VjsfAddDatatable v-bind="context" />
        </template>
      </VJsf> 
 
   <v-row>
      <v-spacer />
      <v-col>
        <v-btn
          block
          large
          color="button"
          class="button_color--text"
          @click="nextStep"
        >
          Suivant
        </v-btn>
      </v-col>
    </v-row>
     </div>
</template>

<style scoped>
</style>

<script>


const schema = {
  type: "object",
  properties: {
    secondariesAssured: {
      title: "Assurés",
      description: "Conjoint et enfants à charge de - de 26 ans",
      properties: {
        secondariesAssured: {
          description: "",
          type: "array",
          "x-display": "custom-inputTable",
          items: {
            type: "object",
          },
          inputTable: {
            // !!!!!!!!!!!!!!!!! Possible de garder la syntaxe de vjsf comme en haut pour un custom component ?
            lastname: {
              header: "Nom",
              type: "string",
              required: true,
              rules: [(v) => !!v],
              hideErrorDetails: true,
            },
            firstname: {
              header: "Prenom",
              type: "string",
              required: true,
              rules: [(v) => !!v],
              hideErrorDetails: true,
            },
            civility: {
              header: "Civilité",
              type: "list",
              remoteListItems:
                "/api/listItems/civilities", 
              remoteListProp: "listItems",
              remoteListKey: "code",
              remoteListValue: "libelleCourt",
              required: true,
              rules: [(v) => !!v],
              hideErrorDetails: true,
            },
            relation: {
              header: "Relation",
              type: "list",
              remoteListItems:
                "/api/listItems/relations",
              remoteListProp: "listItems",
              remoteListKey: "code",
              remoteListValue: "libelle",
              required: true,
              rules: [(v) => !!v || "Relation requise"],
              hideErrorDetails: false,
            },
            maritalStatus: {
              header: "Sit. Mat.",
              type: "list",
              remoteListItems:
                "/api/listItems/marStat",
              remoteListProp: "listItems",
              remoteListKey: "code",
              remoteListValue: "libelle",
              required: true,
              rules: [(v) => !!v],
              hideErrorDetails: true,
            },
            birthDate: {
              header: "Date de naissance",
              type: "date",
              required: true,
              rules: [
                (v) => !!v/* ,
                (v) => {
                      var today = new Date();
                      var parts = v.split("/");
                      var birthDate = new Date(
                        parseInt(parts[2], 10),
                        parseInt(parts[1], 10) - 1,
                        parseInt(parts[0], 10)
                      );

                      var age = today.getFullYear() - birthDate.getFullYear();
                      var m = today.getMonth() - birthDate.getMonth();
                      if (
                        m < 0 ||
                        (m === 0 && today.getDate() < birthDate.getDate())
                      ) {
                        age--;
                      }

                      return (
                        (age >= 18 && age <= 77) && this.editedItem.relation == 'Enfant' ||
                        "L'age doit être compris entre 18 et 77 ans."
                      );
                    },
                
                /* , v => Math.floor((Date.now().toLocaleString('fr-FR') - new Date(v))/1000/60/60/24/365) > 26 &&  this.editedItem.relation == 'Enfant' || 'No way' */,
               ],
            },
            socioProfessionalCategory: {
              header: "CSP",
              type: "list",
              remoteListItems: "/api/listItems/csps",
              remoteListProp: "listItems",
              remoteListKey: "code",
              remoteListValue: "libelle",
              required: false,
              /* rules: [(v) => !!v], */
              hideErrorDetails: true,
            },
            profession: {
              header: "Profession",
              type: "string",
              required: false,
             /*  rules: [(v) => !!v], */
              hideErrorDetails: true,
            },
          },
        },
      },
    },
  },
};
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { required_if } from "vee-validate/dist/rules";
setInteractionMode("eager");

extend("required_if", {
  ...required_if,
  // message: '{_field_} can not be empty',
});

import VJsf from "@koumoul/vjsf";
import "@koumoul/vjsf/lib/deps/third-party.js";
import "@koumoul/vjsf/dist/main.css";
import "regenerator-runtime/runtime";

import axios from "axios";
/* import json from "./risks.json"; */

import VjsfAddDatatable from "../../components/vjsf/VjsfAddDatatable.vue";
import { mapState } from "vuex";
export default {
  name: "Risk",
  props: {

  },
  components: {
    ValidationObserver,
    ValidationProvider,
       VJsf,
    VjsfAddDatatable,
  },
  data: () => ({
    menuBirthDate: false,
    formatedBirthDate: null,
    valid: false,
    /* risks: {}, */
    principalAssured:{
      isPrincipalAssured: true,
      principalAssured:{}},
    model: {
      /* principalAssured: {
          isPrincipalAssured: true,
          principalAssured: {
          },
        }, */
        secondariesAssured: {
          secondariesAssured: [],
        },
    },
    schema,
    options: {
      locale: "fr",
      httpLib: axios,
      httpOptions: {
        baseURL: window.location.origin,
      },
      inputTable: {
        addButtonTitle: "Ajouter des assurés additionnels",
        modalTitle: {
          addItem: "Ajouter un assuré",
          editItem: "Editer un assuré",
        },
      },
      
    },
  }),
  beforeCreated() {
    
   
  },
  created() {
    
  },
  mounted() {

  },
  computed: {
    ...mapState({
      client: (state) => state.estimation.estimate.person,
      risk: (state) => state.estimation.estimate.productRisk,
      itemsLists: (state) => state.estimation.itemsLists,
    }),
  },
  methods: {
    logEvent(key, $event) {
      /* console.log("vjsf event", key, $event); */
    },
    async nextStep() {
       await this.$refs["form"].validate().then((valid) => {
        if (valid) {
         this.$store.commit('estimation/nextEstimationStep')
        } else
          logger.debug('Formulaire de payement invalide')
      });
    },
    verifyAge(dob) {
      var today = new Date();
      var parts = dob.split("/");
      var birthDate = new Date(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10)
      );

      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age >= 18 && age <= 77;
    },
    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString('fr-FR', {timeZone: 'Pacific/Noumea'});
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    'model.secondariesAssured.secondariesAssured': {
      handler: function (secondariesAssured, oldVal)  {
       
        if(oldVal != undefined)
          this.$store.commit('estimation/setSecondariesAssured', secondariesAssured);
      },
      deep: true
    },
    principalAssured: {
      handler: function (principalAssured, oldVal)  {
     
        if(oldVal != undefined)
          this.$store.commit('estimation/setPrincipalAssured', principalAssured.principalAssured);
      },
      deep: true,
      immediate: true,
    },
    'principalAssured.isPrincipalAssured': {
      handler: function (isPrincipalAssured, oldVal)  {
        if(isPrincipalAssured){
        logger.debug('Assignation info client assuré principal')
        this.principalAssured.principalAssured = this.client
        } else {
          logger.debug('Reset info assuré principal')
        this.principalAssured.principalAssured = {}
        }
      }
    },
    'principalAssured.principalAssured.birthDate': {
      handler: function(val, oldVal) {
        this.formatedBirthDate = this.formatDate(val);
        logger.debug('Modification du champ birthDate : ' + val)
      },
      immediate: true,
    },
    /* client: {
      handler: function(val, oldVal) {
        this.$set(this.principalAssured, 'principalAssured', val);
        this.principalAssured.isPrincipalAssured = true;
      },
      deep: true,
    }, */
    
  },
};
</script>









 
<template>
  <v-card>
    <v-row>
      <v-col>
        <h2>Véhicule</h2>
      </v-col>

      <v-col>
        <v-dialog v-model="searchVehicleDialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              large
              color="button"
              class="button_color--text"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>search</v-icon> Rechercher
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Rechercher un véhicule</span>
            </v-card-title>

            <v-card-text>
              <SRA @update-vehicle="updateVehicle" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row v-show="Object.keys(this.risk.vehicle).length > 0">
      <v-col cols="6">
        <p>Marque: {{ this.risk.vehicle.brand }}</p>
        <p>Modèle: {{ this.risk.vehicle.model }}</p>
        <p>Version: {{ this.risk.vehicle.version }}</p>
        <p>Type Mines: {{ this.risk.vehicle.minesType }}</p>
        <p>Code SRA: {{ this.risk.vehicle.SRACode }}</p>
      </v-col>

      <v-col cols="6">
        <p>Energie: {{ this.risk.vehicle.energy }}</p>
        <p>Puissance administrative: {{ this.risk.vehicle.fiscalPower }}</p>
        <p>Carrosserie: {{ this.risk.vehicle.body }}</p>
      </v-col>
    </v-row>

    <ValidationObserver ref="observerVehicle" v-slot="{ valid }">
      <v-form>
        <!-- ----- ACQUISITION ----- -->
        <v-row>
          <v-col cols="3" sm="3"><h2>Acquisition / Usage</h2></v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <ValidationProvider
              v-slot="{ errors }"
              name="immatriculation"
              rules="required"
            >
              <v-text-field
                v-model="risk.vehicleInfos.registration"
                label="Immatriculation *"
                outlined
                :error-messages="errors"
                clearable
                clear-icon="clear"
                required
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="3" sm="3">
            <v-menu
            ref="menuFirstUseDate"
            v-model="menuFirstUseDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            
            <template v-slot:activator="{ on, attrs }">
              <ValidationProvider
                  v-slot="{ errors }"
                  name="date de 1ère mise en circulation"
                  rules="required"
                >
              <v-text-field
                v-model="formatedFirstUseDate"
                v-mask="'##/##/####'"
                label="Date de 1ère mise en circulation *"
                outlined
                prepend-icon="calendar"
                clearable
                clear-icon="clear"
                @click:clear="
                  firstUseDate = '';
                "
                v-bind="attrs"
                @blur="firstUseDate = parseDate(formatedFirstUseDate)"
                v-on="on"
                required
                :error-messages="errors"
              ></v-text-field>
               </ValidationProvider>
            </template>
            <v-date-picker
              v-model="firstUseDate"
              scrollable
              header-color="tertiary"
              color="tertiary_light"
              first-day-of-week="1"
              no-title
              @input="menuFirstUseDate = false"
            ></v-date-picker>
          </v-menu>
            
          </v-col>
          <v-col cols="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="usage"
              rules="required"
            >
              <v-select
                :items="itemsLists.uses"
                v-model="risk.vehicleInfos.use"
                return-object
                item-text="libelle"
                item-value="code"
                label="Usage *"
                outlined
                :loading="selectsLoading"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
         <!-- <v-col cols="3" sm="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="nombre de KM/an"
              rules="integer"
            >
              <v-text-field
                v-model="risk.vehicleInfos.kmsByYear"
                label="Nombre de KM/an"
                outlined
                :error-messages="errors"
                clearable
                clear-icon="clear"
              />
            </ValidationProvider>
          </v-col>-->
        </v-row>

        <v-row>
         <!-- <v-col cols="3">
            <v-select
              :items="itemsLists.acquisitionModes"
              v-model="risk.vehicleInfos.acquisitionMode"
              return-object
              item-text="libelle"
              item-value="code"
              label="Mode d'acquisition"
              outlined
              :loading="selectsLoading"
              clearable
              clear-icon="clear"
            />
          </v-col>-->

          <v-col cols="3" sm="3">
            <v-menu
            ref="menuFirstUseDate"
            v-model="menuAcquisitionDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            
            <template v-slot:activator="{ on, attrs }">
              <ValidationProvider
                  v-slot="{ errors }"
                  name="date d'acquisition"
                  rules="required"
                >
              <v-text-field
                v-model="formatedAcquisitionDate"
                label="Date d'acquisition *"
                outlined
                prepend-icon="calendar"
                clearable
                clear-icon="clear"
                @click:clear="
                  acquisitionDate = '';
                "
                v-bind="attrs"
                
                @blur="acquisitionDate = parseDate(formatedAcquisitionDate)"
                v-on="on"
                required
                :error-messages="errors"
                v-mask="'##/##/####'"
              ></v-text-field>
               </ValidationProvider>
            </template>
            <v-date-picker
              v-model="acquisitionDate"
              scrollable
              header-color="tertiary"
              color="tertiary_light"
              first-day-of-week="1"
              no-title
              @input="menuAcquisitionDate = false"
            ></v-date-picker>
          </v-menu>
            
          </v-col>

          <v-col cols="2" sm="3">
            <v-text-field
              v-model="risk.vehicleInfos.drivingMode"
              label="Mode de conduite"
              outlined
              hide-details
              clearable
              clear-icon="clear"
            />
          </v-col>

         <!-- <v-col cols="2">
           
              <v-text-field
                :value="risk.vehicle.originalPrice"
                label="Prix d'origine"
                outlined
                clearable
                clear-icon="clear"
                disabled
              />
          
          </v-col>

          <v-col cols="2">
            <v-switch
                v-model="risk.vehicleInfos.used"
                flat
                label="Occasion ?"
              />
          </v-col>
          -->
        </v-row>

        <!-- ----- GARAGE ----- -->
        <v-row>
          <v-col cols="3" sm="3"><h2>Garage</h2></v-col>
        </v-row>

        <v-row>
         <!-- <v-col cols="3" sm="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="code postal"
              rules="required|integer|length:5"
              vid="zipCode"
            >
              <v-text-field
                v-model="risk.vehicleInfos.zipCode"
                label="CP *"
                outlined
                :error-messages="errors"
                clearable
                clear-icon="clear"
                @blur="errors.length === 0 ? getCitiesList() : ''"
              />
            </ValidationProvider>
          </v-col>-->

          <!-- Fix rule with zip code
          <v-col cols="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="ville"
              rules="required" 
            >
              <v-select
                :items="itemsLists['cities']"
                v-model="risk.vehicleInfos.city"
                return-object
                item-text="ville"
                item-value="id"
                label="Ville *"
                outlined
                :loading="citiesLoading"
                :disabled="itemsLists['cities'] == 0"
                :error-messages="itemsLists['cities'] == 0?'':errors"
              />
            </ValidationProvider>
          </v-col>
          -->
        </v-row>
        <!-- ----- REMORQUE -----
        <v-row>
          <v-col cols="3" sm="3"><h2>Remorque</h2></v-col>
        </v-row>

        <v-row>
          <v-col cols="3" sm="3">
            <v-text-field
              v-model="risk.vehicleInfos.trailer.registration"
              label="Immatriculation"
              outlined
              hide-details
              clearable
              clear-icon="clear"
            />
          </v-col>

          <v-col cols="3">
            <v-select
              :items="itemsLists.trailerWeights"
              v-model="risk.vehicleInfos.trailer.weight"
              return-object
              item-text="libelle"
              item-value="code"
              label="Poids"
              outlined
              :loading="selectsLoading"
              clearable
                  clear-icon="clear"
            />
          </v-col>

          <v-col cols="3">
            <v-select
              :items="itemsLists.trailerTypes"
              v-model="risk.vehicleInfos.trailer.type"
              return-object
              item-text="libelle"
              item-value="code"
              label="Type"
              outlined
              :loading="selectsLoading"
              clearable
                  clear-icon="clear"
            />
          </v-col>
          <v-col cols="3" sm="3">
            <v-text-field
              v-model="risk.vehicleInfos.trailer.brand"
              label="Marque"
              outlined
              hide-details
              clearable
              clear-icon="clear"
            />
          </v-col>
          <v-col cols="3" sm="3">
            <v-text-field
              v-model="risk.vehicleInfos.trailer.model"
              label="Modèle"
              outlined
              hide-details
              clearable
              clear-icon="clear"
            />
          </v-col>
        </v-row>

       ----- COMPLEMENTS -----
        <v-row>
          <v-col cols="3" sm="3"><h2>Compléments</h2></v-col>
        </v-row>

        <v-row>
          <v-col cols="3" sm="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="nombre de véhicules par foyer"
              rules="integer|required"
            >
              <v-text-field
                v-model="risk.vehicleInfos.householdVehiclesNb"
                label="Nb véhicules foyer *"
                outlined
                :error-messages="errors"
                clearable
                clear-icon="clear"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="3" sm="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="nombre de permis par foyer"
              rules="integer"
            >
              <v-text-field
                v-model="risk.vehicleInfos.householdDriverLicencesNb"
                label="Nb permis foyer"
                outlined
                :error-messages="errors"
                clearable
                clear-icon="clear"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="3" sm="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="nombre de permis de - 3ans"
              rules="integer"
            >
              <v-text-field
                v-model="risk.vehicleInfos.householdYoungDriverNb"
                label="Dont - de 3 ans"
                outlined
                :error-messages="errors"
                clearable
                clear-icon="clear"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="appartenance véhicule"
              rules="required"
            >
              <v-select
                :items="itemsLists.vehicleOwner"
                v-model="risk.vehicleInfos.owner"
                return-object
                item-text="libelle"
                item-value="code"
                label="Appartenance Veh. *"
                outlined
                :error-messages="errors"
                :loading="selectsLoading"
            
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="période sans assurance"
              rules="required"
            >
              <v-select
                :items="itemsLists.withoutInsuranceRanges"
                v-model="risk.vehicleInfos.withoutInsuranceRange"
                return-object
                item-text="libelle"
                item-value="code"
                label="Veh. sans assurance ? *"
                outlined
                :loading="selectsLoading"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="3" sm="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="kilométrage"
              rules="integer"
            >
              <v-text-field
                v-model="risk.vehicleInfos.km"
                label="Kilométrage"
                outlined
                :error-messages="errors"
                clearable
                clear-icon="clear"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
-->
        <!-- ----- SUIVANT ----- -->
        <v-row>
          <v-spacer />
          <v-col>
            <v-btn
              block
              large
              color="button"
              class="button_color--text"
              @click="nextStep"

            >
              Suivant
            </v-btn>
            <ValidationProvider
              v-slot="{ errors }"
              rules="isTrue"
              name="Veuillez sélectionner un véhicule"
            >
            <!-- <span>{{errors[0]}}</span> -->
              <v-input
                v-model="isVehicleSelected"
                :error-messages="errors"
                hidden
                
              />
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>

<style scoped>
</style>

<script>
import {
  required,
  integer,
  length,
  double,
  between,
  alpha_num,
} from "vee-validate/dist/rules";
import SRA from "../../../components/SRA.vue";
import axios from "axios";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapState, mapActions } from "vuex";
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Le champ {_field_} est requis",
});
extend("integer", {
  ...integer,
  message: "Le champ {_field_} doit contenir un nombre",
});
extend("length", {
  ...length,
  message: "Le champ {_field_} doit contenir {length} chiffres",
});
extend("double", {
  ...double,
  message: "Le champ {_field_} doit être une valeur à {decimals} décimale ",
});
extend("between", {
  ...between,
  message: "Le champ {_field_} doit être compris entre {min} et {max}",
});
extend("alpha_num", {
  ...alpha_num,
  message: "Le champ {_field_} doit être alpha-numérique",
});
/* extend("required_if", {
  ...required_if,
  message: "",
}); */

extend("isTrue", {
  validate(value) {
    console.log('isTrue : '+value)
    return value;
  },
  message: "{_field_}",
});

export default {
  name: "VehicleStep",
  components: { SRA, ValidationObserver, ValidationProvider },
  data: () => ({
    searchVehicleDialog: false,

    menuFirstUseDate: false,
    formatedFirstUseDate: null,
    menuAcquisitionDate: false,
    formatedAcquisitionDate: null,
    
    isVehicleSelected: false,

    
  }),
  props: {
  },
  created() {
    this.getVehicleItemsLists();
  },
  mounted() {
    
  },
  computed: {
    ...mapState({
      risk: (state) => state.estimation.estimate.productRisk,
      itemsLists: (state) => state.estimation.itemsLists,
      selectsLoading: (state) => state.estimation.loader['listVehicle'],
      citiesLoading: (state) => state.estimation.loader['listCities'],
    }),
    firstUseDate: {
      get: function() { return this.risk.vehicleInfos.firstUseDate},
      set: function(v) {  
        if(v != undefined){
          this.$store.commit('estimation/updFirstUseDate', v);
        }
      },
    },    
    acquisitionDate: {
      get: function() {return this.risk.vehicleInfos.acquisitionDate},
      set: function(v) {  
        if(v != undefined){
          this.$store.commit('estimation/updAcquisitionDate', v);
        }
      },
    }  ,  
    /* isVehicleSelected() {return Object.keys(this.risk.vehicle).length == 0},   */
    
  },
  methods: {
    ...mapActions("estimation", [
      "getVehicleItemsLists",
      "getCitiesList",
    ]),
    
    /* isVehicleSelected() {return Object.keys(this.risk.vehicle).length > 0}, */
    updateVehicle(vehicle) {
      this.searchVehicleDialog = false;
      this.$store.commit('estimation/updVehicle', Object.assign({}, vehicle));
      if(Object.keys(this.risk.vehicle).length != 0)
        this.isVehicleSelected = true;
    },

    nextStep() {
      this.$refs["observerVehicle"].validate().then((valid) => {
        if (valid) {
          console.log(this.risk)
          this.$store.commit('estimation/nextRiskStep')
        }
      });
    },

    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString('fr-FR', {timeZone: 'Pacific/Noumea'});
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    firstUseDate(val) {
      this.formatedFirstUseDate = this.formatDate(val);
    },
    acquisitionDate(val) {
      this.formatedAcquisitionDate = this.formatDate(val);
    },
  },
};
</script>

<template lang="pug">
  v-row(justify='center')
    v-col(cols='12' sm='4')
      v-card.elevation-3
        v-card-title.justify-center
          v-img(lazy-src='' max-height='100' max-width='236' :src='require(`@/assets/images/logo_white.png`)')
        v-card-subtitle.mb-0.pb-0.pt-5.text-center
          span.text-h6 Bienvenue
          br
          | Merci de bien vouloir vous identifier
          LoginForm
</template>

<!-- <style>
.grecaptcha-badge {
  visibility: hidden !important;
}
</style> -->

<script>
import LoginForm from "@/components/login/LoginForm";

export default {
  name: "Login",
  components: {
    LoginForm,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.isAuthenticated;
    },
  },
  mounted() {
    const recaptcha = this.$recaptchaInstance
    recaptcha.showBadge()
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  data: () => ({
    appDir: process.env.VUE_APP_NAME,
    loadingCheckAuth: false,
    errors: [],
    recaptchaToken: "",
    checkbox: false,
  }),
};
</script>

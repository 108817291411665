<template>
  <div>
    <v-form v-model="valid" ref="updClientForm">
      <v-row>
        <v-text-field v-show="false" v-model="client.id"></v-text-field>
        <v-text-field v-show="false" v-model="client.personType"></v-text-field>
        <v-text-field v-show="false" v-model="client.personCategory"></v-text-field>
        <v-col cols="2">
          <v-select
            :items="itemsLists['civilities']"
            item-text="libelleCourt"
            item-value="code"
            v-model="client.civility"
            return-object
            label="Civilité"
            outlined
            :loading="getClientLoading || listLoading"
            required
            :rules="[(v) => !!v]"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="client.lastname"
            label="Nom"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            required
            :loading="getClientLoading"
            :rules="[(v) => !!v]"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            v-model="client.firstname"
            label="Prénom"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            required
            :rules="[(v) => !!v]"
            :loading="getClientLoading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-select
            :items="itemsLists['maritalStatuses']"
            v-model="client.maritalStatus"
            return-object
            item-text="libelle"
            item-value="code"
            label="Sit. Mat."
            outlined
            :loading="getClientLoading || listLoading"
            required
            :rules="[(v) => !!v, (v) => v.length > 0 || Object.keys(v).length > 0]"
          ></v-select>
        </v-col>
        <v-col cols="4" sm="3">
          <v-menu
            ref="menuBirthDate"
            v-model="menuBirthDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formatedBirthDate"
                label="Date de naissance "
                outlined
                persistent-hint
                prepend-icon="calendar"
                clearable
                clear-icon="clear"
                @click:clear="birthDate = ''"
                v-bind="attrs"
                :loading="getClientLoading"
                @blur="birthDate = parseDate(formatedBirthDate)"
                v-on="on"
                required
                :rules="[(v) => !!v]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="birthDate"
              scrollable
              header-color="tertiary"
              color="tertiary_light"
              first-day-of-week="1"
              no-title
              @input="menuBirthDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-select
            :items="itemsLists['csps']"
            v-model="client.socioProfessionalCategory"
            return-object
            item-text="libelle"
            item-value="code"
            label="Cat. Socioprofessionnelle"
            outlined
            required
            :loading="getClientLoading || listLoading"
            :rules="[(v) => !!v, (v) => v.length > 0 || Object.keys(v).length > 0]"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="client.profession"
            label="Profession"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            :loading="getClientLoading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="client.address.adress1"
            label="Adresse"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            required
            :loading="getClientLoading"
            :rules="[(v) => !!v]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="client.address.city"
            label="Ville"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            required
            :rules="[(v) => !!v]"
            :loading="getClientLoading"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3">
          <v-text-field
            v-model="client.address.zipCode"
            label="Code Postal"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            required
            :rules="[(v) => !!v]"
            :loading="getClientLoading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" sm="3">
          <v-text-field
            v-model="client.mail.mail"
            label="Adresse mail"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            required
            :rules="[(v) => !!v]"
            :loading="getClientLoading"
          ></v-text-field>
        </v-col>
        <v-spacer />
        <v-col cols="4" sm="3">
          <v-select
            :items="itemsLists['countries']"
            v-model="client.address.country"
            return-object
            item-text="libelle"
            item-value="code"
            label="Pays"
            outlined
            required
            :loading="getClientLoading || listLoading"
            :rules="[(v) => !!v, (v) => v.length > 0 || Object.keys(v).length > 0]"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" sm="3">
          <v-text-field
            v-model="client.phone.number"
            label="Numéro de téléphone"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            required
            :rules="[(v) => !!v]"
            :loading="getClientLoading"
          ></v-text-field>
        </v-col>

        <v-col cols="4" sm="3">
          <v-checkbox
            true-value="true"
            false-value="false"
            :input-value="client.phone.acceptSms"
            hide-details
          >
            <template v-slot:label>
              <div>Accepte SMS</div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <v-col v-if="this.isEstimate == true" cols="6" sm="3">
          <v-dialog v-model="searchDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn block large color="button" class="button_color--text" v-bind="attrs" v-on="on">
                <v-icon left>search</v-icon>Rechercher un client
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Rechercher un client</span>
              </v-card-title>

              <v-card-text>
                <ClientSearch :isEstimate="true" @update-client="updateClient" />
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>

        <v-col v-if="this.isEstimate == true" cols="6" sm="3">
          <v-btn block large color="button" class="button_color--text" @click="nextStep">
            <v-icon left>right</v-icon>Suivant
          </v-btn>
        </v-col>
        <v-col v-if="this.isEstimate == false" cols="6" sm="3">
          <v-btn block large color="button" class="button_color--text" @click="addClient;">
            <v-icon left>add</v-icon>
            {{
            this.clientId == "-1"
            ? "Nouveau client"
            : "Modifier client"
            }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<style scoped>
</style>

<script>
import ClientSearch from "../client/Search.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "CreationModificationClient",
  components: {
    ClientSearch,
  },
  data: () => ({
    valid: false,
    searchDialog: false,
    menuBirthDate: false,
    formatedBirthDate: null,
  }),
  props: {
    isEstimate: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: String,
      default: "-1",
    },
  },
  created() {
    this.getClientItemsLists();
  },
  mounted() {},
  computed: {
    ...mapState({
      listLoading: (state) => state.estimation.loader["listClient"],
      getClientLoading: (state) => state.estimation.loader["getClient"],
      itemsLists: (state) => state.estimation.itemsLists,
      client: (state) => state.estimation.estimate.person,
    }),
    birthDate: {
      get: function () {
        return this.client.birthDate;
      },
      set: function (v) {
        if (v) {
          this.$store.commit("estimation/updBirthDate", v);
        }
      },
    },
  },
  methods: {
    ...mapActions("estimation", ["getClientItemsLists", "fetchClient"]),
    verifyAge(dob) {
      if (dob != undefined) {
        var today = new Date();
        var parts = dob.split("/");
        var birthDate = new Date(
          parseInt(parts[2], 10),
          parseInt(parts[1], 10) - 1,
          parseInt(parts[0], 10)
        );

        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        return age >= 18 && age <= 77;
      } else return false;
    },
    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString('fr-FR', {timeZone: 'Pacific/Noumea'});
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    nextStep() {
      if (this.$refs["updClientForm"].validate()) {
        this.$store.commit("estimation/nextEstimationStep");
      }
    },
    addClient() {},
    async updateClient(clientId) {
      this.searchDialog = false;
      await this.fetchClient(clientId);
    },
  
  },
  watch: {
    itemsLists: {
      handler: (v) => console.log(v),
      deep: true,
    },
    birthDate(val) {
      this.formatedBirthDate = this.formatDate(val);
    },
    
  },
};
</script>

<template>
  <v-container fluid class="container">
    <ValidationObserver ref="paymentModeObs" v-slot="{ valid }">
      <v-form style="width: 100%">
        <v-card>
          <v-card-title> Mode de paiement </v-card-title>

          <v-card-text>
            <ValidationProvider
              v-slot="{ errors }"
              vid="selectPaymentCash"
              name="mode de paiement comptant"
              rules="required"
            >
              <v-select
                v-model="payementModes.comptant"
                :items="itemsLists.paymentTypesCMPT"
                item-text="libelle"
                item-value="code"
                label="Mode de paiement comptant *"
                return-object
                :error-messages="errors"
                :loading="listsLoading"
                outlined
                clearable
                hide-selected
                required
                @input="$refs.paymentModeObs.validate()"
              ></v-select>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              vid="selectPaymentRenew"
              name="mode de paiement renouvellement"
              rules="required"
            >
              <v-select
                v-model="payementModes.renouvellement"
                :items="itemsLists.paymentTypesCMPT"
                item-text="libelle"
                item-value="code"
                label="Mode de paiement renouvellement *"
                return-object
                :error-messages="errors"
                outlined
                clearable
                :loading="listsLoading"
                hide-selected
                required
                disabled
              ></v-select>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              vid="selectPaymentSec"
              name="mode de paiement termes secondaires"
              :rules="{ required: splitting != 'A' }"
            >
              <v-select
                v-if="splitting != 'A'"
                v-model="payementModes.termeSecondaire"
                :loading="listsLoading"
                :items="itemsLists.paymentTypesTESEC"
                item-text="libelle"
                item-value="code"
                label="Mode de paiement termes secondaires *"
                return-object
                :error-messages="errors"
                outlined
                clearable
                hide-selected
                required
              ></v-select>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              vid="debitDay"
              name="jour de prélévement"
              rules="required"
            >
              <v-select
                v-model="payementModes.debitDay"
                :items="itemsLists.debitDays"
                label="Jour de prélévement *"
                :error-messages="errors"
                item-text="libelle"
                item-value="code"
                outlined
                clearable
                hide-selected
                :loading="listsLoading"
                required
              ></v-select>
            </ValidationProvider>
          </v-card-text>

          <v-card-title> Adresse bancaire </v-card-title>

          <v-card-text>
            <ValidationProvider
              v-slot="{ errors }"
              vid="selectType"
              name="type d'adresse bancaire"
              :rules="{ required: isRequired }"
            >
              <v-select
                v-model="bankAccount.type"
                :items="itemsLists.types"
                item-text="libelle"
                item-value="code"
                label="Type d'adresse bancaire *"
                return-object
                :error-messages="errors"
                outlined
                :loading="listsLoading"
                clearable
                hide-selected
              ></v-select>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              vid="selectPays"
              name="pays"
              :rules="{ required: isRequired }"
            >
              <v-select
                v-model="bankAccount.country"
                :items="itemsLists.countries"
                item-text="libelle"
                item-value="code"
                label="Pays *"
                return-object
                :error-messages="errors"
                outlined
                :loading="listsLoading"
                clearable
                hide-selected
              ></v-select>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              vid="selectDevise"
              name="devise"
              :rules="{ required: isRequired }"
            >
              <v-select
                v-model="bankAccount.currency"
                :items="itemsLists.currencies"
                item-text="libelle"
                item-value="code"
                label="Devise *"
                :loading="listsLoading"
                return-object
                :error-messages="errors"
                outlined
                clearable
                hide-selected
              ></v-select>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              vid="titulaire"
              name="titulaire"
              :rules="{ required: isRequired, max: 50 }"
            >
              <v-text-field
                outlined
                :error-messages="errors"
                v-model="bankAccount.accountOwner"
                label="Titulaire *"
                class="upperCaseInput"
                clearable
              ></v-text-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              vid="codeIBAN"
              name="code iban"
              :rules="{ required: isRequired, validateIban: true }"
            >
              <v-text-field
                :error-messages="errors"
                v-model="bankAccount.ibanCode"
                label="Code IBAN *"
                outlined
                class="upperCaseInput"
                clearable
              ></v-text-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              vid="codeBIC"
              name="code bic"
              :rules="{ required: isRequired, validateBic: true }"
            >
              <v-text-field
                v-model="bankAccount.bicCode"
                :error-messages="errors"
                :counter="50"
                label="Code BIC *"
                outlined
                class="upperCaseInput"
                clearable
              ></v-text-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              vid="domiciliation"
              name="domiciliation"
              :rules="{ required: isRequired, max: 50 }"
            >
              <v-text-field
                v-model="bankAccount.domiciliation"
                :error-messages="errors"
                :counter="50"
                label="Domiciliation *"
                outlined
                class="upperCaseInput"
                clearable
              ></v-text-field>
            </ValidationProvider>
          </v-card-text>

          <v-card-actions>
            <v-btn
              block
              large
              color="button"
              class="button_color--text"
              :disabled="createContractLoading || $store.state.transformation.contractId != ''"
              :loading="createContractLoading"
              @click="contractValidation"
            >
              Valider votre contrat
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>autorenew</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<style>
.container {
  padding: 0 !important;
}
.upperCaseInput input {
  text-transform: uppercase;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<script>


import { mapState, mapActions } from "vuex";
function validateIBAN(iban) {
    // 1. Normaliser l'IBAN
    iban = iban.replace(/\s/g, '').toUpperCase();

    // 2. Valider la longueur et le format de base
    if (iban.length !== 27 || !iban.match(/^NC\d+/)) {
        return false;
    }

    // 3. Déplacer les 4 premiers caractères à la fin
    iban = iban.substring(4) + iban.substring(0, 4);

    // 4. Convertir les lettres en nombres
    let numericIBAN = '';
    for (let i = 0; i < iban.length; i++) {
        const character = iban[i];
        numericIBAN += (character >= 'A' && character <= 'Z') ? (character.charCodeAt(0) - 'A'.charCodeAt(0) + 10) : character;
    }

    // 5. Calculer le modulo 97
    let remainder = 0;
    for (let i = 0; i < numericIBAN.length; i++) {
        remainder = (remainder * 10 + parseInt(numericIBAN[i], 10)) % 97;
    }
    console.log("remainder",remainder);

    return remainder === 1;
}


const ibantools = require("ibantools");
import { required, max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { localize } from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
localize("fr", fr);
setInteractionMode("eager");

extend("required", {
  ...required,
  // message: '{_field_} can not be empty',
});

extend("max", {
  ...max,
  // message: '{_field_} can not be empty',
});

extend("validateIban", {
  message: (field) => "Le format du code IBAN n'est pas correct",
  validate: (value) => {
    console.log("Validate IBAN",value)
    return validateIBAN(value) || ibantools.isValidIBAN(value);
  },
});




extend("validateBic", {
  message: (field) => "Le format du code BIC n'est pas correct",
  validate: (value) => {
    return ibantools.isValidBIC(value);
  },
});

export default {
  name: "PayementMethod",

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({}),
  props: {},
  created() {
    this.getPayementModesItemsLists();
    if (!this.$store.state.transformation.estimate.person.bankAccount) {
      logger.debug('Création d\'un compte bancaire vide')
      this.$store.commit("transformation/createBankAccount"); 
    }
  },
  mounted() {},
  computed: {
    ...mapState({
      payementModes: (state) => state.transformation.estimate.payementModes,
      bankAccount: (state) => state.transformation.estimate.person.bankAccount,
      splitting: (state) => state.transformation.estimate.formula.splittingCode,
      itemsLists: (state) => state.transformation.itemsLists,
      listsLoading: (state) => state.transformation.loader["listModePayement"],
      createContractLoading: (state) =>
        state.transformation.loader["createContract"],
    }),
    isRequired: function () {
      return (
        this.payementModes.comptant.code == "IN_PRLVT" || this.splitting != "A"
      );
    },
  },
  methods: {
    ...mapActions("transformation", [
      "getPayementModesItemsLists",
      "createContract",
      "getContractDetails",
    ]),
    async contractValidation() {
      logger.debug('Click "Valider votre contrat"')
      await this.$refs["paymentModeObs"].validate().then(async (valid) => {
        if (valid) {
          await this.createContract();
          if (this.$store.state.transformation.contractId != "")
            await this.getContractDetails();
          if (Object.keys(this.$store.state.transformation.contract).length > 0)
            this.$store.commit("transformation/nextTransformationStep");
        } else
          logger.debug('Formulaire de payement invalide')
      });
    },
    closeDialog: function () {
      this.$emit("close-dialog");
    },
    updateBankAdressDetails(updValue) {
      this.$set(this.estimateModel, "bankAdressDetails", updValue);
    },
  },
  watch: {
    "payementModes.comptant": {
      handler: function (val, oldVal) {
        this.$store.commit("transformation/updPayModeRenou", val);
      },
      deep: true,
    },
  },
};
</script>
